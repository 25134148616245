import * as React from 'react'
import { string } from 'prop-types'
import Text from "./Text";
import Button from "../components/buttons/Button";
import Link from "./Link";

export const ErrorPage = ({
	header,
	body,
	buttonText,
	buttonLink
}) => {
	return (
			<div data-testid="error-page" className="flex items-center justify-center absolute flex-col inset-0 text-center">
				<Text as="h1" className="text-2xl">{header}</Text>
				<Text className="text-md mt-lg" dangerouslySetInnerHTML={{__html: body}} />
				{(buttonText && buttonLink) && (
					<Button
						as={Link}
						to={buttonLink}
						className="w-auto px-2xl mt-xl"
					>
						{buttonText}
					</Button>
				)}
			</div>
	)
}

ErrorPage.propTypes = {
	header: string,
	body: string,
	buttonText: string,
	buttonLink: string
}
