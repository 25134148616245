import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Helmet } from 'react-helmet-async'
import Layout from '@/container/Layout'
import {navigate} from "gatsby";
import { ErrorPage } from "@/utils/ErrorPage";
import { getSSOError } from "@/utils/sso/getSSOError";
import Spinner from "../../utils/Spinner";

function SSOLogin({ location }) {
	const dispatch = useDispatch();
	const { loggedIn } = useSelector(state => state.user);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState({});

	React.useEffect(() => {

		const [, token, masqueradeSchemeYear ] = location.pathname.split('/').filter(Boolean)

		if (!loggedIn && token)
		{
			dispatch({
				type: 'user/REQUEST_SSO_LOGIN',
				payload: { token, masqueradeSchemeYear }
			})
				.then(() => {
					setLoading(false)

					navigate("/app/home/")
				})
				.catch( () => {
					const magicLinkPrefix = token.substring(0, 3)

					setError(getSSOError(magicLinkPrefix))

					setLoading(false)
				})
		}
	}, [ loggedIn, dispatch ])



	return (
		<Layout>
			<Helmet>
				<title>{`Home | SSO Login`}</title>
			</Helmet>
			<>
				{loading && (
					<div data-testid="loading" className="absolute inset-0 flex items-center justify-center z-100">
						<Spinner data-testid="page-load" style={{ width: 100, height: 100 }} />
					</div>
				)}
				{error && (
					<ErrorPage
						header={error.header}
						body={error.body}
					/>
				)}
			</>
		</Layout>
	)
}

export default SSOLogin
