const MASQUERADE = "msq"

export const getSSOError = prefix =>
{
	switch ( prefix )
	{
		case MASQUERADE:
			return {
				header: "Ooops your link has expired",
				body : "This link has expired. Don't worry close this tab and start the masquerade session again."
			}

		default:
			return {
				header: "Oops somethings gone wrong.",
				body: "We are unable to connect you. Please contact us"
			}
	}
}
